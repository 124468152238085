// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  project: 'luz-da-serra-portal',
  version: '0.0.1',
  breakpoint: 768,
  transition: {
    animation: 'opacity',
    loader: true
  },
  url: 'https://luzdaserra.2na8.dev',
  data: {
    url: 'https://storage.googleapis.com/luz-da-serra-portal.appspot.com/customers/luzdaserra/pages/versions',
    fallback: '/data'
  },
  legacy: {
    url: 'https://www.luzdaserra.com.br'
  },
  pixel: {
    id: '259888415526551'
  },
  firebase: {
    apiKey: 'AIzaSyCJG8vO4HBfhe1PKVfgmc83YlHDrP5xBBM',
    authDomain: 'luz-da-serra-portal.firebaseapp.com',
    projectId: 'luz-da-serra-portal',
    storageBucket: 'luz-da-serra-portal.appspot.com',
    messagingSenderId: '221578618081',
    appId: '1:221578618081:web:a3b567dc2c6f1b4067c3a0',
    measurementId: 'G-R83PS5XLNE'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
